.LayoutContainer {
    width:auto;
    margin: auto;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    
}
@media(min-width:2000px) {
    .LayoutContainer {
        width: 1400px;
        margin: auto;
        background-color: #fff;
    }
}
body {
    background-color: rgb(0, 0, 0);
}
@media(max-width:600px) {
    .LayoutContainer {
        width: 100%;
        margin: auto;
    }
}