.FooterClass p{
    text-align: left;
    font-size: 0.75rem;
    
}
.ContactDiv p{
    text-align: right;
    font-size: 0.75rem;
    line-height: 0.5px;
}
.ContactDiv{
    margin-left: auto;
}