.BackColorGrey{
    background-color: #F5F5F5;
    margin: 0.2rem auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 0.5rem;
}
.rowMyeTrun{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 0 0.8rem 0;
}
.BackBorderColor{
    border-right: 1px solid #A8A8A8;
    height: 120px;
}
.MyReturnButton{
    text-align: center;
}
.MyReturnPar{
    border: 1px solid #222222;
    width: 100%;
    position: relative;
    margin-left: auto;
    border-radius: 0.6rem;
    padding:0.3rem 1rem 0.3rem 1rem;
}
.MyRetuTooltip{
    visibility: hidden;
}
.quickQty p:hover{
    background-color: rgb(221, 221, 221);
}
.myReturnQty {
    width: 100%;
    border: 1px solid #222222;
    border-radius: 0.4rem;
    display: flex;
    justify-content: space-between;
    /*margin-top: 18px;*/
}
.myReturnQty p{
    cursor: pointer;
    margin: 0;
    width: 20px;
    background-color: #F5F5F5;
    padding: 0.2rem;
    border-radius: 0.3rem;
}
.MyRetuTooltip{
    visibility: hidden;
    width: 130px;
    background-color: #fff;
    color: #222222;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    right: 1.6rem;
    top: -2rem;
    position: absolute;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 20px 0px;
    z-index: 9999;
}
.MyRetuTooltip::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 8px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
}
.MyReturnPar:hover .MyRetuTooltip{
    visibility: visible;
}
.MyReturnButton button{
    width: 100%;
    color: #3D97EA;
    border-radius: 0.6rem;
    text-align: center;
    border: 1px solid #3D97EA;
    margin-top: 3.5rem;
    margin-left: auto;
    background-color: transparent;
    font-size: 0.8rem;
    padding:0.3rem 1rem 0.3rem 1rem;
}
.quickFirstSecond{
    display: flex;
    justify-content:flex-start;
}
.quickImgBoxSecond {
    background-color: transparent;
    border-radius: 0.5rem;
    padding: 0.3rem 0.2rem 0.3rem 0.2rem;
    margin: 0.2rem;
    height: auto;
    width: 120px;
    object-fit: contain;
    align-items: center; /* Center image vertically */
    justify-content: center;
}
    .quickImgBoxSecond img {
        height: 112px;
        align-items: center; /* Center image vertically */
        justify-content: center;
    }
.quickImgContentSecond h5{
    font-size: 0.8rem;
}
.quickImgContentSecond p{
    font-size: 0.8rem;
}
.MyReturnProdCardInput{
    border: none;
    outline: none;
    text-align: center;
}
.placeReturnButton{
    background-color: #3D97EA;
    color: #fff;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 0.5rem;
}
.ReturnNotTle  h6{
    font-size: 0.8rem;
}
.ProductReView{
    border:1px solid #c4c3c3;
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.ProductReView h6{
    font-size: 0.7rem;
    margin-top: 1.5rem;
}
.ProdImgView{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.ProdImgView img{
    background-color: #e7e6e6;
    width: 120px;
    height: 120px;
    border-radius: 0.5rem;
}
.ProDetView{
    border: 1px solid  #e7e6e6;
}
.ProductCont2 p{
    font-size: 0.7rem;
}
.ProductBetbor{
    margin-top: 0.3rem;
    height: 120px;
    padding: 0.3rem;
    border-right: 1px solid #e7e6e6;
}

.ProductReContent h5{
    font-size: 0.8rem;
}
.ProductReContent p{
    font-size: 0.7rem;
}
.ReturnPolicyClass h3{
    font-size: 0.8rem!important;
}