.ListWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.5rem;
  position: absolute;
  top: 44px;
  z-index: 10;
  background: #fff;
  border-radius: 4px;
  width: 62%;
  max-height: 240px;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ListItem{
  text-align: left;
  padding: 16px 8px;
  width: 100%;
  background: #fff;
  outline: none;
  border: none;
  &:hover {
    background: #e2e2e2;
  }
}
