.CategoriesHead{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.RowComBgStrip{
    background-color: #EDEDED;
}
.CategoriesHeadTWo{
    margin-top: 0.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.CategoriesHead h5{
    font-size: 0.8rem;
    padding: 0rem 1.5rem 0rem 0rem;
}
.CategoriesHeadTWo h5{
    font-size: 0.8rem;
    padding: 0rem 0.9rem 0rem 0rem;
}
.CategoriesSearch{
    position: relative;
}
.CategoriesSearch .form-control{
    padding-top: 1rem;
    border: 1px solid #b3b3b3;
    font-size: 0.9rem;
    height: 30px;
    border-radius: 0.7rem;
}
.CategoriesSearch i{
    font-size: 0.8rem;
    color: #8a8888;
    position: absolute;
    top: 9px;
    right: 23px;
}
.CategoriesHead h5:nth-child(1){
    border-right: 1px solid #A8A8A8;
}
.CategoriesHead h5:nth-child(2){
    border-right: 1px solid #A8A8A8;
}
.CategoriesHead h5:nth-child(3){
    border-right: 1px solid #A8A8A8;
}
.CategoriesHead h5:nth-child(4){
    border-right: 1px solid #A8A8A8;
}
.CategoriesHead h5:nth-child(5){
    border-right: 1px solid #A8A8A8;
}
.CategoriesHead h5:nth-child(6){
    border-right: 1px solid #A8A8A8;
}
.CategoriesHead h5:nth-child(1){
    border-right: 1px solid #A8A8A8;
}
.CategoriesHeadTWo h5:nth-child(1){
    border-right: 1px solid #A8A8A8;
}
.CategoriesHeadTWo h5:nth-child(2){
    border-right: 1px solid #A8A8A8;
}
.CategoriesHeadTWo h5:nth-child(3){
    border-right: 1px solid #A8A8A8;
}
.CategoriesHeadTWo h5:nth-child(4){
    border-right: 1px solid #A8A8A8;
}
.CategoriesHeadTWo h5:nth-child(5){
    border-right: 1px solid #A8A8A8;
}
.CategoriesHeadTWo h5:nth-child(6){
    border-right: 1px solid #A8A8A8;
}.CategoriesHeadTWo h5:nth-child(7){
    border-right: 1px solid #A8A8A8;
}
.CategoriesHeadTWo h5:nth-child(8){
    border-right: 1px solid #A8A8A8;
}
@media(max-width:600px){
    .CategoriesHead{
        display: flex;
        flex-direction: column;
    }
}