.CartHeader h3{
    font-size: 1.5rem;
}
.CartContent{
    padding: 0.4rem;
    margin:1rem 0 1rem 0.5rem;
    height:5rem;
    overflow-y:scroll;
    background-color: #F5F5F5;
    border-radius: 0.8rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.Cartbanner{
    background-color: #F5F5F5;
    border-radius: 0.8rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: 280px;
    margin: 0.8rem;
}
.CartCompPara p{
    font-weight: 600;
}
.CardProdDiv{
    position: relative;
    display: flex;
    justify-content: center;
}
.ViewMarBtn{
    position: absolute;
    bottom: -5rem;
    width: 100%; 
    text-align: center;
}
.ViewMarBtn button{
    border: 1px solid #222222;
    font-size: 0.79rem;
    border-radius: 0.4rem;
    width: 80%;
    background-color: #fff;
}
.quickImgContent h5{
    font-weight: 600!important
}
.AmOrderPlaced{
    text-align: center;
    position: fixed;
    top: 35%;
    left: 0;
    right: 0;
}
.AmOrderPlaced button{
    background-color: #3D97EA;
    color: #fff;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 0.3rem .6rem;
    border-radius: 0.5rem;
}
.AmOrderPlaced .fa-circle-check{
    font-size: 5rem;
    color: #3D97EA;
}
.AmOrderPlaced h6{
    font-size: 1.6rem;
}
.AmOrderPlaced p{
    text-align: center;
    color: #A8A8A8;
}