.ContactBox{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 2rem 0.8rem 0 0.8rem;
    border-radius: 2rem;
    width: fit-content;
}
.ContactSubAdd{
    padding: 1rem;
}
.ContactAddress{
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
}
.ContactAddress p{
    font-size: 0.8rem
}
.ContactButton{
    background-color: #0E45D4;
    height: 40px;
    border: none;
    color: #fff;
    padding: 0.2rem 1rem 0.2rem 1rem;
    border-radius:0.9rem;
}