.Banner{
    width: 100%;
    height: 25px;
    background-color: #FFAB7B;
    display: flex;
    color: #222222;
    font-size: 0.7rem;
    justify-content: space-evenly;
    align-items: center;
}
.BannerNav{
    display: flex;
}