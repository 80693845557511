.spinner-overlay {
    position: fixed; /* Ensures the spinner overlays the entire page */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex; /* Flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    background-color: rgba(255, 255, 255, 0.7); /* Optional: semi-transparent background */
    z-index: 9999; /* Ensure it's above other content */
}

.spinner {
    width: 50px; /* Adjust the size as needed */
    height: 50px;
    border: 5px solid rgba(0, 0, 0, 0.1); /* Outer ring */
    border-top: 5px solid #3498db; /* Spinner color */
    border-radius: 50%;
    animation: spin 1s linear infinite; /* Spinner animation */
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.5rem;
    color: #333;
}

.HomeBorder{
    padding: 2.6rem 0 1.5rem 0;
    border-style: solid;
    border-width: 1px;
    border-left: 1px;
    border-color: #c5c3c3;
}
.HomeSlick{
    position: relative;
}
.HomeSlick i{
    font-size: 1rem;
    color: #222222;
}
.HomeRNext{
    position: absolute;
    left: 4.5rem;
    bottom: 7rem;
    height: 30px;
    padding: 0.4rem;
    background-color: #ffffff5b;
    width: 30px;
    border-radius: 100%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 7px 8px;
}
.HomeRPrev{
    position: absolute;
    right:4.5rem;
    bottom: 7rem;
    padding: 0.4rem;
    border-radius: 100%;
    height: 30px;
    background-color: #ffffff5b;
    width: 30px;
    
    box-shadow: rgba(0, 0, 0, 0.24) 0px 7px 8px;
}
.RangSlider{
    max-width: 100%;
    display: flex;
    overflow-x: scroll;
}
.HomeBorder2{
    padding: 2.6rem 0 0.5rem 0;
    border-style: solid;
    border-width: 1px;
    border-right: 1px;
    border-color: #c5c3c3;
}
@media(max-width:600px){
    .HomeBorder{
        padding: 2.6rem 0 0.5rem 0;
        border-style: solid;
        border-width: 1px;
        border-left: 0!important;
        border-color: #c5c3c3;
    }
    .HomeBorder2{
        padding: 2.6rem 0 0.5rem 0;
        border-style: solid;
        border-width: 1px;
        border-right: 0!important;
        border-color: #c5c3c3;
    }
}
.HomeBorder2 h1{
    line-height: 0.5;
}
.HomeBorder h1{
    line-height: 0.5;
}
.HomePara{
    font-size: 0.9rem;
    padding: 0.5rem;
}
.HomeRanPro{
    position: relative;
    background-color: #ECECEC;
    height: 200px;
    width: 7.5rem;
    margin-bottom: 1rem;
    padding-top: 0.5rem;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 7px 8px;
    text-align: center;
    position: relative;
}
.HomeRanPro:hover{
    transform: scale(1.1);
    transition: 1s ease-in-out;
}
.HomeRanPro img{
    z-index: 9999999;
    height: 6.5rem;
    width: 100%;
    position: absolute;
    top: 1.5rem;
    object-fit: contain;
    object-position: center;
    mix-blend-mode: multiply;
    cursor: pointer;
}
.HomeRanPro img:hover{
    transform: scale(1.2); 
    transition: 1.2s ease-in-out;
}
.HomeSpec h3{
    font-size: 1.5;
    text-align: left;
}
.HomeRanPro h6{
    height: 65px;
    width: 100%;
    bottom: -8px;
    font-weight: 400;
    z-index: 999;
    position: absolute;
    background-color: #fff;
    padding: 0.5rem;
    font-size: 0.8rem;
    border-end-start-radius: 1rem;
    border-end-end-radius: 1rem;
}

.HomeImgBox h6{
    text-align: center;
    bottom:0;
    color: #222222;
    font-size: 0.8rem;
    font-weight: 400;
    padding: 0.4rem 1rem 0.3rem 1rem;
    letter-spacing: 1px;
}
.HomeImgBox img{
    height: 80px;
    width: auto;
}
.HomeSpec .HomeImgBox{
    background-color: #fff;
    position: relative;
    border-radius: 3rem;
    height: 175px;
    width: 175px;
    text-align: -webkit-center;
    padding: 2.1rem 0 2rem 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.container-fluid {
    max-width: 100%!important;
    padding-right: 80px!important;
    padding-left: 80px!important;
    margin-right: auto!important;
    margin-left: auto!important;
}
@media(max-width:600px){
    .LandHead {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 1rem 0 1rem 0;
    }
}
.HomeModalPart button{
    background-color: #A8A8A8;
    color: #fff;
    font-size: 0.8rem;
    font-weight: 400;
    margin:0 0.3rem 0 0.3rem;
    padding:0.6rem 1rem 0.6rem 1rem;
    letter-spacing: 1px;
    transition: 0.3s ease-in;
    border-radius: 0.4rem;
}
.HomePartSec p{
    font-weight: 500;
    font-size: 0.7rem;
    margin-top: 0.5rem;
}
.HomePartSec{
    display: flex;
    justify-content: center;
    align-items: end;
}
.HomePartBtnCont{
    background-color: #71BBFF;
    padding: 0.4rem 2rem 0.4rem 2rem;
    color: #fff;
    margin-top: 0.8rem;
    border-radius: 0.6rem;
}
.HomeUploadBtn button{
    border: 1px solid #AFAFAF;
    color: #222222;
    padding: 0.3rem 1rem 0.3rem 1rem;
    background-color: transparent;
    border-radius: 0.5rem;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.HomeUploadBtn i{
    padding-left: 0.5rem;
}
.HomeUploadBtn p{
    font-size: 1rem;
    padding-bottom: 1rem;
}
.pade5{
    padding-right: 4rem;
}
.pads5{
    padding-left: 4.8rem;
}
.custom-slider{
    height: 320px;
}