.DashChartCont {
    height: 410px;
    width: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 0.5rem;
    margin-bottom: 1.3rem;
}

.DashChartCont .DashChartTitle {
    height: 44px;
    text-align: center;
    display: flex;
    font-size: 0.9rem;
    justify-content: center;
    align-items: center;
    background-color: #CBE6FF;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
}
.graphClass{
    display: flex;
    flex-direction: column;
}
.DashChartTitle h5 {
    font-size: 0.9rem;
}

.DashCustomSelect select {
    outline: none;
}
.DasPosCLas{
 text-align: center;
 position: relative;
}
.DasPosCLas p{
    position: absolute;
    color: #222222;
    top: 5.9rem;
    left: 43%;
    font-size: 0.7rem;
}
.DasPosCLasSec{
    text-align: center;
    position: relative;
   }
   .DasPosCLasSec p{
       position: absolute;
       color: #222222;
       top: 5.9rem;
       left: 35%;
       font-size: 0.7rem;
   }