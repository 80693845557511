.LoginBg{
    height: 100vh;
    width: auto;
    background-image: url('../../assets/login/loginbg.png');
    background-size: cover;
}
.LoginFont{
    font-size: 0.7rem;
    padding-top: 0.7rem;
    position: absolute;
    margin-left: 6rem;
}
.LoginBox .form-control{
    font-size: 0.9rem;
    height: 40px;
}
.LoginBox{
    position: relative;
    background-color: #fff;
    border-radius: 2rem;
    padding: 2rem 3rem 2rem 3rem;
    box-shadow: rgba(104, 217, 255, 0.1) 5px 5px,
     rgba(104, 217, 255, 0.09) 10px 10px,
      rgba(104, 217, 255, 0.08) 15px 15px,
       rgba(104, 217, 255, 0.07) 20px 20px,
        rgba(104, 217, 255, 0.06) 25px 25px;}
@media(max-width:600px){
    .LoginBox{
        padding: 1rem;
    }
}
.LoginForgot{
    display: flex;
    justify-content: end;
    align-items:center;
    padding-bottom: 2rem;
    margin-top: -0.5rem;
}
.Fortext{
    line-height: 1;
    font-weight: 400!important;
    color: black;
    text-decoration: none;
    cursor: pointer;
    font-size: 0.7rem;
}
.LoginInact h6{
    font-size:1.6rem;
}
.LoginInact p{
    text-align: center;
    color: #A8A8A8;
}
.LoginInact .fa-circle-exclamation{
    font-size: 4.5rem;
    color: #F04B50;
}
.LoginInact button{
    border: none;
    outline: none;
    padding: 0.5rem 1rem;
    font-weight: 500;
    color: #fff;
    background-color: #F04B50;
}
.LoginInvalidClass{
    margin: auto;
    left: 0;
    right: 0;
    top: 27%;
}