/* Ensure the container maintains relative positioning */
.col-md-6 {
    position: relative; /* Ensure child elements like the button are positioned relative to this container */
}

/* Make the button container responsive */
.btn-quote-container-header {
    position: absolute;
    background-color: #3D97EA !important; /* Customize button color */
    color: white !important; /* Text color */
    border: none !important;
    border-radius: 0.3rem !important;
    top: 88% !important;
    right: 44% !important;
    transform: translate(0, -50%) !important;
    z-index: 10 !important;
    font-size: 0.7rem !important;
    margin-bottom: 0.5rem !important;
    padding: 0.3rem 1rem 0.3rem 1rem !important;
    outline: none !important;
}
/* Ensure the image is fully responsive */
/*.responsive-img {
    width: 100%;*/ /*Maintain responsiveness of the image */
    /*height: auto;*/ /* Preserve aspect ratio */
/*}*/

.SearchResult{
    z-index: 9999;
    max-height: fit-content;
    position: absolute;
    overflow-y:visible;
    top: 38px;
    width: 80%;
    left: 7.5rem;
    border-radius: 0.5rem;
    background-color: #fff;
    animation-name: searchRe;
    animation-duration: 1s;
    border-bottom-left-radius: 5rem;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .13);
    -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .13);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .13);
    -webkit-backdrop-filter: blur(5px); 
    backdrop-filter: blur(5px);
    font-size: 13px;
    font-family: inherit;
    line-height: normal;
}
.UserHeadName{
    display: flex;
    justify-content: start;
}
.UserHeadName h6{
    font-size: 0.7rem;
    color: #3D97EA;
}
.CustomNotifcations{
    display: flex;
    justify-content: space-between;
    align-items: start;
    border-bottom: 1px solid #A8A8A8;
    margin: 0.3rem auto;
}
.CustomNotifcations:nth-last-child(1){
    border-bottom: none;
}
.CustomNotBar button{
    padding: 5px 10px;
    border-radius: 0.9rem;
    font-size: 0.6rem;
}
.CustomNotList h6{
    font-size: 0.8rem;
    font-weight: 400!important;
    cursor: pointer;
}
.button-active {
    background-color:#3d96ea73;
    color: #222222;
    border: 1px solid #fff;
    padding: 5px 10px;
    outline: none;
  }
  
  .button-inactive {
    background-color: transparent;
    /* border: 1px solid #222222; */
    outline: none;
  }
.CustomNotList h5{
    font-size: 1rem;
    font-weight: 400!important;
}
.CustomNotBar{
    display: flex;
    justify-content: space-between;
}
.CustomNotCont i{
    margin-top: 0.5rem;
    height: 30px;
    width: 30px;
    padding: 0.5rem;
    border-radius: 1rem;
    color: #fff;
    border: 1px solid #fff;
    background-color: #3d96ea73;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.CustomNotCont{
    display: flex;
    align-items: center; 
    justify-content: space-between;
    margin: 0.5rem;
}
.UserHeadNotModal option{
    font-size: 0.7rem;
}
.UserHeadNotModal select {
    background-color: transparent;
    outline: none;
    padding: 0 0.2em 0 0;
    border-radius: 0.7rem;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
  }
.CustomNotifcations .CustomNotCont h6{
    font-size: 0.7rem;
    margin-left: 0.5rem;
    margin-top: 0.5rem;
}
.CustomNotImg{
    background-color: #ebebeb;
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin-bottom: 0.3rem;
}
.CustomNotImg img{
    height: 50px;
    width: 50px;
}
.itemToolContent .itemOffer{
    background-color: #3D97EA;
    height: 20px;
    width: 60px;
    font-weight: 600;
    color: #fff;
    padding: 0.2rem;
    position: absolute;
    left:3rem;
    bottom: 0.3rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .13);
    border-radius: 5rem;
}
.itemToolContent .itemSpec{
    font-size: 0.6rem;
    color: #fff;
    background-color: #3D97EA;
    text-align: left;
    padding: 0.3rem;
}
.itemTooltip{
    visibility: hidden;
}
.itemResult:hover .itemTooltip{
    visibility: visible;
}
.itemResult > .itemTooltip img{
    width: 100px;
    height: auto;
}
.itemToolContent{
    margin: 0 0.5rem 0 0;
    font-size: 0.7rem;
}
.itemTooltip{
    display: flex;
    justify-content: space-between;
    width: 55%;
    background-color: #fff;
    color: #222222;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    right: 0rem;
    top: 1.2rem;
    position:absolute;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 20px 0px;
    z-index: 99999999;
}
.Searchbox::backdrop {
    background-color: rgba(255,255,255,0);
}
@keyframes searchRe {
    from{opacity: 0;}
    to{opacity: 1;}
}
.notFoundClas img{
    height: 200px;
}
.itemResult img{
    height: 25px;
    width: auto;
}
.itemResult:hover{
    border: 1px solid #A9D6FF;
    background-color: #ebebeb;
}
.itemResult{
    height: 30px;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.5s;
    margin: 0.5rem;
    border: none;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    background-color: #fff;
    border-radius: 0.4rem;
}
.itemResult .itemReCont span{
    margin: 0 0 0 0.6rem;
    font-size: 0.7rem;
}
.Searchbox .rs-dropdown-toggle, .rs-dropdown-toggle.rs-btn {
    -ms-flex-align: center;
    align-items: center;
    display: inline-block;
    display: -ms-inline-flexbox;
    display: inline-flex;
    gap: 6px;
    padding-right: 32px;
    position: relative;
    z-index: 5;
    background: #A9D6FF;
}
.UserPageIcon img{
    width: 25px;
    height: 25px;
 }
input:focus::placeholder {
    color: transparent;
  }

  .dropdown-menu{
    border: none;
    font-size: 0.8rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
/*.RowPad{
    padding: 0 3rem 0 3rem;
}*/
.RowPad {
    display: flex; /* Ensure horizontal alignment */
    justify-content: space-between; /* Space out items horizontally */
    align-items: center; /* Align items vertically in the center */
    padding: 0 1rem; /* Adjust horizontal padding */
    gap: 1rem;
}
    .RowPad .col {
        flex: 0 0 auto; /* Prevent columns from stacking or shrinking */
        text-align: center; /* Center-align the content */
    }
.UserHomeList{
    list-style: none;
    padding: 0;
    display: flex;
    margin-top: 0.5rem;
    justify-content: space-between;
    align-items: center;
}
.UserHomeList li{
    margin: 0rem 0rem 0 2rem;
}
.UserHomeList li:nth-child(1){
    margin: 0;
}
@media(max-width:600px){
    .UserHomeList{
        margin-top: 0.5rem;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content:flex-start;
        align-items: flex-start;
    }
    .borderRightClass{
        width: 100%;
        padding: 0.3rem 0 0.3rem 0;
        border-right: 0!important;
        border-bottom: 1px solid #222222!important;
    }
}
.UserHomeShadowScroll {
    position: fixed; /* Fixed position when scrolled */
    top: 0; /* Stick to the top */
    left: 0; /* Align to the left */
    right: 0; /* Align to the right */
    opacity: 1; /* Fully opaque */
    background: rgba(255, 255, 255, 1); /* Solid background when fixed */
}

.UserHomeShadow {
    padding: 1px;
    transition: all 0.3s ease; /* Smooth transition */
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .13);
    z-index: 1000;
}
.borderRightClass{
    height: 22px;
    border-right: 1px solid #A8A8A8;
}
.UserHomeList li > a{
    padding:0 2.5rem 0 0rem;
}
.UserHomeList li > a{
    text-decoration: none;
    font-size: 0.7rem;
    color: #222222;
}
.CustomDrop{
    position: relative;
}
.CustomDrop .fa-chevron-down{
    position: absolute;
    right: 0.8rem;
    top: 4px;
    cursor: pointer;
}
.Searchbox{
    position: relative;
}
.Searchbox .fa-magnifying-glass{
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9999;
}
.NavClassSec{
    padding: 0!important;
}
.navbar .nav-item > .nav-link{
    font-size:0.69rem;
    color: #222222;
    font-weight: 400;
}
.UserHomeList button{
    -webkit-animation: blink 3.0s linear infinite;
    -moz-animation: blink 3.0s linear infinite;
    -ms-animation: blink 3.0s linear infinite;
    -o-animation: blink 3.0s linear infinite;
    animation: blink 3.0s linear infinite;
}

@keyframes blink {
    0% { box-shadow: 0 0 15px #3D97EA; }
    50% { box-shadow: none; }
    100% { box-shadow: 0 0 15px #3D97EA; }
}

@-webkit-keyframes blink {
    0% { box-shadow: 0 0 15px #3D97EA; }
    50% { box-shadow: 0 0 0; }
    100% { box-shadow: 0 0 15px #3D97EA; }
}