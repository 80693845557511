.MyProfileStrip {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #F5F5F5;
    padding: 0.6rem;
    margin: 1rem 0 0 0;
    border-radius: 0.5rem;
    margin-left: 1%;
    width: 96.5%;
}
.MyProfileSecondStrip h5{
    font-size: 0.8rem;
}
.MyProfileCard{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.MyProfileSecondStrip{
    color: #222222;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    display: flex;
    padding:0.5rem 1.3rem 0.5rem 1.3rem;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    background-color: #A9D6FF;
    height: 40px;
}
.paymentTwo{
    text-align: right;
}

.MyProfileUnderLine{
    border-bottom: 1px solid #A8A8A8;
}
.pendingDay {
    margin-left: auto;
    width: 120px;
    height: 60px;
    text-align: center;
    background-color: #FAB1B1;
    border-radius: 0.3rem;
    color: #fff;
    display: flex;
    justify-content: center; /* Horizontally center */
    align-items: center;
}
.paymentViaWrap{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding:0 0 1rem 0;
    border-radius: 0.8rem;
}
.paymentTotal{
    display: flex;
    justify-content: space-between;
    position: relative;
    bottom: 0;
    padding: 0.5rem;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    font-weight: bold;
}
.MyProfileForm .form-control{
    height: 30px;
    width: 60%;
}
.MyProfileForm .date-control{
    height: 30px;
    width: 60%;
}
.MyProfileForm2  .form-control{
    height: 30px;
    width: 81%;
}
.MyProfileForm3  .form-control{
    height: 30px;
    width: 63%;
}
.payViaBt2{
    border-radius: 0.3rem;
    background-color: #3D97EA;
    border:none;
    outline: none;
    color: #fff;
    height:30px;
}
.payViaBt1{
    border-radius: 0.3rem;
    background-color: #fff;
    border:1px solid #A8A8A8;
    height:30px;
}
.paymentButton{
    background-color: #3D97EA;
    color: #fff;
    font-weight: 500;
    padding: 0.5rem 2.5rem 0.5rem 2.5rem;
    border-radius: 0.5rem;
}
.paymentOne p:nth-child(1),p:nth-child(3){
    font-weight: 500;
    font-size: 0.8rem;
}
.PaymentBox {
    position: relative;
    max-height: 320px;
    overflow-y: scroll;
}
.paymentSec{
    align-items: center;
    padding:0.2rem 1rem 0.2rem 0.6rem;
    display: flex;
    justify-content: space-between;
}
.payLastDetil{
    overflow-y: auto;
    position: relative;
}
.payLastDetil th{
    position: sticky;
    top: 0;
    padding-left: 1.2rem;
    background-color: #A9D6FF;
}
.payLastDetil1 th{
    position: sticky;
    top: 0;
    padding-left: 1.2rem;
    background-color: #A9D6FF;
}
.payLastDetil th:nth-child(1){
    border-top-left-radius: 1rem;
}
.payLastDetil1 th:nth-child(1){
    border-top-left-radius: 1rem;
}
.payLastDetil th:nth-child(6){
    border-top-right-radius: 1rem;
}
.payLastDetil1 th:nth-child(7){
    border-top-right-radius: 1rem;
}
tbody{
    height: 100px;       
    overflow-y: auto;   
    overflow-x: hidden;
}
td{
    font-size: 0.8rem;
}
th{
    font-size: 0.9rem;
    font-weight: 500;
}
.payScrollDiv{
    position: relative;
    height: 25rem;
    overflow: scroll;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}