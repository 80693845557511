.MyOrderSearchbar{
    position: relative;
}
.MyOrderSearchbar i{
    position: absolute;
    right: 10px;
    top: 10px;
}
.MyOrderInvoice p{
    float: right;
    cursor:pointer;
    margin-right: 1rem;
    font-size: 0.8rem;
}
.MyOrderSearchbar .form-control{
    border-radius: 0.8rem;
    border:1px solid #EDEDED;
    margin: 0.5rem 0 0 0;
}
.MyOrderBanner{
    border-radius: 0.8rem;
    height: 63vh;
    background-color: #A9D6FF;
}
.MyorderTabs{
    margin: 1rem 0 0 0;
    height: 53px;
    border-radius: 1rem;
    background-color: #F5F5F5;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.MyorderTabs .MyorderBorder{
    border-right: 2px solid #A8A8A8;
    padding: 0 3rem 0 0rem;
    height: 27px;
}
.MyorderCard .paraData{
    font-size: 0.65rem;
    padding: 0.5rem 0 0 1.2rem;
    line-height: 0.8;
}
.MyorderInv{
    display: flex;
    justify-content: end;
    padding: 1rem;
}
.MyorderInv h6{
    font-size: 0.8rem;
}
.MyOrderModalSearch{
    background-color: #F5F5F5;
    padding: 0.6rem;
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
}
.MyModalTable p{
    text-align: left;
}
.MyModalTable{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #E8E8E8;
    padding: 0 0 0.5rem 0;
}
.MyOrderModalSearch .form-control{
    border-radius: 0.6rem;
    height: 30px;
}
.MyOrderModalSearch i{
    position: absolute;
    right: 25px;
    top: 46px;
}
.modalParaOne,.modalParaOne2,.modalParaOne3{
    font-size: 0.8rem;
}
.modalParaOne3 p{
    text-align: justify;
}
.modalParaOne2 p{
    text-align: right;
}
.MyModalLine{
    border-bottom: 1px solid #AFAFAF;
    margin: 0.3rem 0 0.3rem 0;
}
.IndexTableCls th {
    font-size: 0.8rem;
    font-weight: 400!important;
}
.ModalIndexPar{
    height: 30px;
    width: auto;
    font-weight: 500;
    border-radius: 0.5rem;
    padding: 0.4rem 0.8rem 0.7rem 0.7rem;
    background-color: #E8E8E8;
}
.MyModalContSec{
    min-height: 75px;
    overflow-y: scroll;
}
.MyModalContent{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.MyorderModal{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding:0.8rem 1rem 0.5rem 1.2rem;
    border-top-right-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
    top: 0;
    background-color: #A9D6FF;
}
.MyorderModal h6{
    font-size: 0.8rem;
}
.Orderbutton:hover{
    background-color: #A9D6FF;
    transition: 0.5s ease-in;
}
.MyretuHJdh{
    display: flex;
    margin-top: 2.5rem;
    flex-direction: column;
    margin-left: 1.6rem;
}
.Orderbutton{
    border: 1px solid #222222;
    outline: none;
    width: 109px;
    height: 40px;
    font-size: 0.7rem;
    margin:0.5rem 0 0 0;
    padding: 0.5rem 1rem 0.5rem 1rem;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    position: relative;
    background-color: transparent;
    border-radius: 0.5rem;
    justify-content: end;
}
.MyorderTabs h6{
    cursor: pointer;
}
.MyorderCard {
    margin-bottom: 20px;
    top: 30px;
    position: relative;
    /*height: 220px;*/
    height: 185px;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.MyorderDivsec {
    /*padding: 0.5rem 0.5rem 1rem 0.5rem;*/ 
    padding: 0.2rem 0.2rem 0.2rem 0.2rem;
    max-height: 30rem;
    overflow-y: scroll;
    overflow-x: hidden;
}
.MyorderCard .MyorderHead{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding:0.8rem 1rem 0.5rem 1.2rem;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    top: 0;
    background-color: #A9D6FF;
}
.MyorderCard .MyorderHead h6{
    font-size: 0.8rem;
}
input:focus::placeholder{
    display: none;
}


/**time line css */
.MyReturnButton{
    margin-top: -2rem;
    border: none;
    background: transparent;
    display: flex;
}
.fa-arrow-rotate-left{
    transform: rotate(120deg)
}
.MyOrderTimeLine{
    width: 100%;
    margin: 4.2rem  0 0 1rem;
    display: flex;
    justify-content: center;
}
.MyOrderTimeLine .line{
    width: 150px;
    position: relative;
    padding: 1rem 0 0 0;
    border-bottom: 1px solid rgb(0, 255, 55);
}
.MyOrderTimeLine .line2{
    width: 150px;
    position: relative;
    padding: 1rem 0 0 0;
    border-bottom:1px solid #AFAFAF;
}
.MyOrderTimeLine .line2:nth-last-child(1){
    width: 90px;
    position: relative;
    padding: 1rem 0 0 0;
    border-bottom: 0;
}
.MyOrderTimeLine .line2::before{
    content: "";
    display: block;
    height: 15px;
    width: 15px;
    position: absolute;
    top: 8px;
    border-left: 1px solid #A8A8A8;
    border-radius: 5rem;
    background-color:#A8A8A8;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.MyOrderTimeLine .desc p{
    margin-top: 0.8rem;
    font-size:0.6rem;
    line-height: 1;
    font-weight: 500;
}
.MyOrderTimeLine .desc2 p{
    margin-top: 0.8rem;
    font-size:0.6rem;
    line-height: 1;
    font-weight: 500;
}
.MyOrderTimeLine  .desc{
    position: absolute;
    width: 110px;
    right: 20px;
    line-break: auto;
    top: -58px;
}
.MyOrderTimeLine  .desc2{
    position: absolute;
    width: 110px;
    right: 38px;
    line-break: auto;
    top: -58px;
}
.MyOrderTimeLine .line2 .desc{
    position: absolute;
    width: 90px;
    right: 10px;
    line-break: auto;
    top: -52px;
}

.MyOrderTimeLine .line::after{
    content: "";
    display: block;
    height: 15px;
    width: 15px;
    position: absolute;
    top: 8px;
    border-radius: 5rem;
    background-color:#6CCC3F;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.QuickRo2{
    border-radius: 0.8rem;
    height:34.5rem;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    text-align: -webkit-center;
}
.QuickFeatureImg2{
    background-color: #fff;
    margin: 0.8rem 0rem 0.8rem 0rem;
    height: 200px;
    width: auto;
    text-align: center;
    padding: 0.8rem 0 0 0;
    position: relative;
    border-radius: 0.8rem;
}
.QuickMarg{
    margin: 0.5rem;
    position: relative;
}
.QuickFeatureImg2{
    height: 200px;
}
.QuickFeatureImg2 img{
    width: auto;
    height: 90px;
}
.QuickFeaBtn2 {
    background-color: #3D97EA;
    color: #fff;
    width: 94%;
    /*padding: 0.5rem;*/
    border-radius: 0.8rem;
    margin-left: 6%;
    height: 35px;
}
.QuickFeatureCont{
    bottom: 0;
    position: absolute;
}
.QuickFeatureCont2{
    font-size: 0.8rem;
    text-align: center;
    position: absolute;
    padding: 0.3rem;
    bottom: 0;
    width: 100%;
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
    background-color: #EDEDED;
}





.MyOrderTimeLine2{
    width: 100%;
    margin: 4.2rem  0 0 1rem;
    display: flex;
    justify-content: center;
}
.MyOrderTimeLine2 .line{
    width: 150px;
    position: relative;
    padding: 1rem 0 0 0;
    border-bottom: 1px solid rgb(0, 255, 55);
}
.MyOrderTimeLine2 .line2{
    width: 150px;
    position: relative;
    padding: 1rem 0 0 0;
    border-bottom:1px solid #AFAFAF;
}
.MyOrderTimeLine2 .line2:nth-last-child(1){
    width: 90px;
    position: relative;
    padding: 1rem 0 0 0;
    border-bottom: 0;
}
.MyOrderTimeLine2 .line2::before{
    content: "";
    display: block;
    height: 15px;
    width: 15px;
    position: absolute;
    top: 8px;
    border-left: 1px solid #A8A8A8;
    border-radius: 5rem;
    background-color:#A8A8A8;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.MyOrderTimeLine2 .desc p{
    margin-top: 0.8rem;
    font-size:0.6rem;
    line-height: 1;
    font-weight: 500;
}
.MyOrderTimeLine2 .desc2 p{
    margin-top: 0.8rem;
    font-size:0.6rem;
    line-height: 1;
    font-weight: 500;
}
    .MyOrderTimeLine2 .desc {
        position: absolute;
        width: 110px;
        right: 50px;
        line-break: auto;
        top: -52px;
    }
.MyOrderTimeLine2  .desc2{
    position: absolute;
    width: 110px;
    right: 38px;
    line-break: auto;
    top: -58px;
}
    .MyOrderTimeLine2 .line2 .desc {
        position: absolute;
        width: 90px;
        /*right: 10px;*/
        right: 75px;
        line-break: auto;
        top: -52px;
    }
.MyOrderTimeLine2 .line::after{
    content: "";
    display: block;
    height: 15px;
    width: 15px;
    position: absolute;
    top: 8px;
    border-radius: 5rem;
    background-color:#6CCC3F;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}



.MyOrderTimeLine3 {
    width: 100%;
    margin: 4.2rem 0 0 1rem;
    display: flex;
    /*justify-content: center;*/
}

    .MyOrderTimeLine3 .line {
        width: 150px;
        position: relative;
        padding: 1rem 0 0 0;
        border-bottom: 1px solid rgb(0, 255, 55);
    }

    .MyOrderTimeLine3 .line3 {
        width: 150px;
        position: relative;
        padding: 1rem 0 0 0;
        border-bottom: 1px solid #AFAFAF;
    }

        .MyOrderTimeLine3 .line3:nth-last-child(1) {
            width: 90px;
            position: relative;
            padding: 1rem 0 0 0;
            border-bottom: 0;
        }

        .MyOrderTimeLine3 .line3::before {
            content: "";
            display: block;
            height: 15px;
            width: 15px;
            position: absolute;
            top: 8px;
            border-left: 1px solid #A8A8A8;
            border-radius: 5rem;
            background-color: #A8A8A8;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }

    .MyOrderTimeLine3 .desc p {
        margin-top: 0.8rem;
        font-size: 0.6rem;
        line-height: 1;
        font-weight: 500;
    }

    .MyOrderTimeLine3 .desc3 p {
        margin-top: 0.8rem;
        font-size: 0.6rem;
        line-height: 1;
        font-weight: 500;
    }

    .MyOrderTimeLine3 .desc {
        position: absolute;
        width: 110px;
        right: 9px;
        line-break: auto;
        top: -52px;
    }

    .MyOrderTimeLine3 .desc3 {
        position: absolute;
        width: 110px;
        right: 38px;
        line-break: auto;
        top: -58px;
    }

    .MyOrderTimeLine3 .line3 .desc3 {
        position: absolute;
        width: 90px;
        right: 23px;
        line-break: auto;
        top: -54px;
    }
    

    .MyOrderTimeLine3 .line::after {
        content: "";
        display: block;
        height: 15px;
        width: 15px;
        position: absolute;
        top: 8px;
        border-radius: 5rem;
        background-color: #6CCC3F;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }


