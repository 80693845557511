@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Mukta:wght@200;300;400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');
* {
    font-family: "Mukta", sans-serif;
    font-family: "Poppins", sans-serif;
}
:root{
    --var-ruppe-font-hind: "Hind", sans-serif;
    --var-ruppe-font-inter:"Inter", sans-serif;
}
.fade-image-className{
    animation-name: fadeCase;
    animation-duration: 1.5s;
}
 @keyframes fadeCase {
    0%{opacity: 0;}
    100%{opacity: 1;}
 }
 
 html,body{
    background-color: rgb(255, 255, 255);
 }