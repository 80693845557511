

.QuickStrip{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.QuickSecondStrip{
    width: 100%;
}
.QuickSecondStrip p{
    margin: 0;
    font-size: 0.8rem;
}
.QuickSecondStrip h5{
    font-size: 1rem;
}
.QuickSecondStrip{
    color: #222222;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    display: flex;
    padding: 0.5rem;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    background-color: #A9D6FF;
    height: 40px;
}
.QuickStrip h3{
    font-size: 1.5rem;
}
.quickImgContent p{
    font-weight: 400;
}
.QuickStrip p{
    font-weight: 500;
}
.QuickOrderBorder{
    border-bottom: 2px solid #A8A8A8;
}
.QuickSecond{
    margin-top: 0.3rem;
    padding: 0;
    text-align: right;
}
.QuickSecond p{
    font-size: 0.7rem;
    line-height: 1.2;
}

.QuickFeatureImg{
    height:200px;
    position: relative;
    padding-top: 1.5rem;
    border-radius: 0.8rem;
    margin-bottom: 1.2rem;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.QuickFeatureImg img{
    height: 100px;
}
.QuickFeatureCont{
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
    padding: 0.3rem;
    font-size: 0.6rem;
    border-bottom-right-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
    background-color: #EDEDED;
}

/*.QuickSearchbtn {
    border: 1px solid #222222;
    font-size: 0.8rem;
    padding: 0.4rem;
    border-radius: 0.5rem;
}

.QuickSearchBar {
    position: relative;
    margin: 2rem 0 2rem 0;
    background-color: #EDEDED;
    border-radius: 0.5rem;
    padding: 0.6rem 0.6rem 0.6rem 0.6rem;
}

.QuickSearchBar .form-control{
    height: 35px;
    border-radius: 0.8rem;
    border: 1px solid #EDEDED;
}
.QuickSearchBar i.fa-magnifying-glass{
    position: absolute;
    top: 22px;
    right: 185px;
}
.QuickSearchBar .catDropdown{
    margin:0.4rem 0 0 1rem;
}

.QuickSearchBar .catDropdown{
    width: 100px;
    cursor: pointer;
}*/

.QuickSearchBar {
    display: flex;
    flex-wrap: wrap; /* Allows wrapping to handle smaller screens */
    align-items: center; /* Vertically aligns items */
    justify-content: space-between; /* Distributes items evenly */
    margin: 2rem 0;
    background-color: #EDEDED;
    border-radius: 0.5rem;
    padding: 0.6rem;
    box-sizing: border-box; /* Ensures padding is included in the width */
}

    .QuickSearchBar .catDropdown {
        flex: 1; /* Allows it to grow/shrink dynamically */
        min-width: 100px; /* Ensures it doesn�t shrink too much */
        cursor: pointer;
        margin: 0.4rem 0;
    }

    .QuickSearchBar .form-control {
        flex: 3; /* Input takes more space */
        height: 35px;
        border-radius: 0.8rem;
        border: 1px solid #EDEDED;
        padding-right: 30px; /* Space for the search icon */
    }

    .QuickSearchBar i.fa-magnifying-glass {
        position: absolute;
        top: 50%; /* Centers it vertically */
        transform: translateY(-50%);
        right: 10%; /* Adjust based on input's right padding */
        color: #888;
    }

.QuickSearchbtn {
    margin-left:1rem;
    flex: 1; /* Button shares space equally */
    border: 1px solid #222222;
    font-size: 0.8rem;
    padding: 0.4rem;
    border-radius: 0.5rem;
    min-width: 100px; /* Prevents shrinking */
    text-align: center;
}

.ListWrapper {
    position: absolute;
    background-color: #fff;
    z-index: 10;
    width: 100%; /* Match the input width */
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 0.3rem;
    margin-top: 0.2rem;
}

.ListItem {
    padding: 0.5rem;
    cursor: pointer;
    border-bottom: 1px solid #f0f0f0;
}

    .ListItem:hover {
        background-color: #f5f5f5;
    }


.QuickPaie p{
    /* line-height: 1px; */
}
.QuickRowSecond {
    border-radius: 0.8rem;
    padding: 1rem;
    margin: 1.5rem 1rem 0 0;
    position: sticky;
    /*overflow-y: scroll;*/
    top: 8.1rem;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.QuickRowSecond .paraClass{
    color: #3D97EA;
}

.QuicKRowClass{
    background-color: #CBE6FF;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    align-content: center;
}
.QuicKRemove{
    border: 1px solid #222222;
    font-size: 0.79rem;
    border-radius: 0.4rem;
    width: 80%;
    background-color: #fff;
    margin: 1.2rem 0 0 0;
}
.QuickAddRem{
    border: 1px solid #222222;
    font-size: 0.79rem;
    border-radius: 0.4rem;
    width: 80%;
    background-color: #fff;
    margin: 0rem 0 0.8rem 0.5rem;
}
.QuickCusMar{
    margin-bottom: 0.5rem;
}
.QuickNotFimg{
    width: 100px;
    padding-top: 1rem;
}
.QuickNotFimg2{
    width: 80px;
    padding-top: 0.5rem;
}
.img-fluid-pr{
    width:-webkit-fill-available;
    height: 60%;
    mix-blend-mode: multiply;
    object-position: center;
    object-fit: contain;
}
.img-fluid-pr:hover{
    transform: scale(1);
    z-index: -9999;
    width: 100%;
    margin-top: -2rem;
    object-position: center;
    object-fit:contain;
    transition:1s ease-in-out;
}
.QuickFeaBtn{
    background-color: #3D97EA;
    color: #fff;
    width: 96%;
    padding: 0.5rem;
    border-radius:0.8rem;
}
.QuickUnderline2{
    margin: 0.5rem 0rem 0 0rem;
    border-bottom:1px solid #A8A8A8;
}
.QuickRowSecond h5{
    font-weight: 600;
    color: #222222;
    font-size: 0.9rem;
}
.QuickUnderline{
    margin-left: 0.8rem;
    padding-top: 0.6rem;
    width: 97%;
    margin-bottom: 0.5rem;
    border-bottom:1px solid #A8A8A8;
}

.QuickAddbtn{
    border-radius: 0.5rem;
    color: #fff;
    display: block;
    width: 90%;
    margin-left: auto;
    padding: 0.5rem 1rem 0.5rem 1rem;
    background-color: #3D97EA;
}
/*.quickQty{
    width: 80%;
    border: 1px solid #222222;
    border-radius: 0.3rem;
    display: flex;
    margin-top: 0.3rem;
    align-items: center;
    justify-content: space-between;
}
    .quickQty p:hover {
        background-color: rgb(221, 221, 221);
    }

    .quickQty p {
        cursor: pointer;
        margin: 0;
        width: 20px;
        background-color: #F5F5F5;
        padding: 0.2rem;
        border-radius: 0.3rem;
    }*/
.quickQty {
    width: 80%;
    border: 1px solid #222222;
    border-radius: 0.3rem;
    display: flex;
    margin-top: 0.3rem;
    align-items: center;
    justify-content: space-between; /* Distributes items with space in between */
    padding: 0.2rem; /* Add padding for better spacing */
    box-sizing: border-box; /* Ensures padding is included in the width */
}
    .quickQty p:hover {
        background-color: rgb(221, 221, 221);
    }
    .quickQty p {
        cursor: pointer;
        margin: 0;
        width: 10%; /* Relative width to adjust with container */
        max-width: 30px; /* Ensures it doesn�t grow too large */
        min-width: 20px; /* Prevents shrinking too much */
        background-color: #F5F5F5;
        padding: 0.2rem;
        border-radius: 0.3rem;
        text-align: center; /* Centers the content inside */
        display: flex;
        justify-content: center;
        align-items: center;
    }

.ProdCartInput {
    border: none;
    background: transparent;
    appearance: none;
    width: 50%; /* Dynamic width to adapt to container size */
    max-width: 60px; /* Restrict max width */
    outline: none;
    text-align: center;
}
.ProdLasInput {
    border: none;
    background: transparent;
    appearance: none;
    width: 50%; /* Dynamic width to adapt to container size */
    max-width: 60px; /* Restrict max width */
    outline: none;
    text-align: center;
}

.quickQty div {
    flex-grow: 1; /* Input container grows to fill remaining space */
    display: flex;
    justify-content: center; /* Center the input horizontally */
}


.quickImgContent {
    width: 70%;
}
    .quickImgContent p {
        font-size: 0.8rem;
        line-height: 15px;
    }
.quickImgContent h5{
    font-size: 0.8rem; 
}

.QuicKWidth{
    display: flex;
    justify-content:baseline;
    max-width: 100%;
    /* overflow: scroll; */
}
.QuickBoxHeight{
    padding: 0.5rem;
    width: 100%;
    max-height: 500px;
    /* overflow-y: scroll; */
    overflow-x: hidden;
}

/*.quickFirst {
    display: flex;
    justify-content: space-between;
}

.quickImgSec {
    height: 110px;
    width: 110px;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 1rem;
    text-align: center;
    background-color: #F5F5F5;
    object-fit: contain;
}*/

.quickFirst {
    display: flex;
    justify-content: space-between;
}

.quickImgSec {
    height: 110px;
    width: 110px;
    padding: 0.5rem;
    border-radius: 1rem;
    text-align: center;
    background-color: #F5F5F5;
    display: flex;
    align-items: center; /* Center image vertically */
    justify-content: center; /* Center image horizontally */
}

    .quickImgSec img {
        height: 100%; /* Fill the container */
        width: 100%; /* Fill the container */
        object-fit: contain; /* Ensure the aspect ratio is preserved */
        border-radius: inherit; /* Optional: match border-radius of container */
    }

.quickImgBox {
    height: auto;
    width: 75px;
    padding: 0.5rem 0.5rem 0rem 0.5rem;
    border-radius: 1rem;
    object-fit: contain; 
    text-align: center;
    background-color: #F5F5F5;
}
.quickImgBox img{
    height:75px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
/*.ProdLasInput{
    border: none;
    background: transparent;
    appearance: none;
    width: 90px;
    outline: none;
    text-align: center;
}*/
/*.ProdCartInput{
    border: none;
    background: transparent;
    appearance: none;
    width: 50px;
    outline: none;
    text-align: center;
}*/

