/* Ensure the container maintains relative positioning */
.col-md-6 {
    position: relative; /* Ensure child elements like the button are positioned relative to this container */
}

/* Make the button container responsive and stay at the top-right corner */
.btn-quote-container1 {
    position: absolute;
    background-color: #3D97EA; /* Customize button color */
    color: white; /* Text color */
    border: none;
    border-radius: 0.3rem;
    top: 90%;
    right: 6%;
    transform: translate(0, -50%);
    z-index: 10;
    font-size: 0.7rem;
    margin-bottom: 0.5rem;
    padding: 0.3rem 1rem 0.3rem 1rem;
    outline: none;
}
.btn-quote-container2 {
    position: absolute;
    background-color: #3D97EA; /* Customize button color */
    color: white; /* Text color */
    border: none;
    border-radius: 0.3rem;
    top: 90%;
    right: 6%;
    transform: translate(0, -50%);
    z-index: 10;
    font-size: 0.7rem;
    margin-bottom: 0.5rem;
    padding: 0.3rem 1rem 0.3rem 1rem;
    outline: none;
}
/* Ensure the image is fully responsive */
.responsive-img {
    width: 100%; /*Maintain responsiveness of the image */
    height: auto; /* Preserve aspect ratio */
    border-radius: 1.5rem;
}

.custom-dropdown-toggle {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-top-left-radius: 0.7rem;
    border-bottom-left-radius: 0.7rem;
    background-color: #f9f9f9;
    cursor: pointer;
    color: #000;
    /* Change this based on the design */
    transition: background-color 0.3s ease;
}

.custom-dropdown-toggle:hover {
    background-color: #e6e6e6;
    /* Change to desired hover effect */
}

.custom-dropdown-toggle i {
    margin-left: 8px;
    /* Space between text and arrow */
}

.CustomDropList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.UserPageButton {
    background-color: #A9D6FF;
    color: #222222;
    font-size: 0.9rem;
    border-radius: 0.7rem;
}

.UserPageButton:hover {
    background-color: #A9D6FF;
}

.Searchbox .form-control {
    font-size: 0.9rem;
    border-radius: 0.7rem;
}

.UserPageIcon i {
    font-size: 1.5rem;
}

.UserPageTypo i {
    color: #3D97EA;
    font-size: 1.1rem;
    margin: 0.6rem;
}

.UserPageCounter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -0.5rem 0 0 0;
}

.UserPageCounter h5 {
    font-size: 1rem;
    font-weight: 600 !important;
    text-align: center;
    padding: 1.2rem 0 0 0;
    border-radius: 5rem;
    height: 60px;
    width: 60px;
    color: #fff;
}

.UserPageCounter h6 {
    font-size: 0.9rem;
    padding: 1rem;
}

.FeatureClass h5 {
    font-size: 1.25rem;
}

.FeatureClass p {
    font-size: 0.8rem;
    padding-top: 1rem;
    padding-left: 1.5rem;
    margin-left: -21%;
}

.UserPageTypo h5 {
    font-size: 1rem;
}

.UserPageTypo {
    position: relative;
    width: 430px;
    height: 40px;
    margin: -2.3rem 0 0 0;
    text-align: center;
    background-color: #fff;
    z-index: 999;
    padding: 0.3rem 0 0 0;
    border-top-right-radius: 5rem;
    border-top-left-radius: 5rem;
}

.UserDivSection {
    display: flex;
    justify-content: center;
    height: 0.3rem;
}

.green {
    background-color: #21D997;
    font-weight: 500 !important;
}

.blue {
    background-color: #3D97EA;
}

.orange {
    background-color: #FFAB7B;
}

.red {
    background-color: #FD8586;
}

.nav-item .nav-link {
    font-size: 0.9rem;
    font-weight: 300 !important;
}

.nav-item {
    padding: 0 1rem 0 1rem;
}

.BorderNavLik {
    padding-right: 0rem 2 rem 0 0;
    height: 35px;
    border-right: 1px solid #A8A8A8;
}

.nav-item:nth-child(2) {
    border-right: 1px solid #A8A8A8;
}

.nav-item:nth-child(3) {
    border-right: 1px solid #A8A8A8;
}

.nav-item:nth-child(4) {
    border-right: 1px solid #A8A8A8;
}

.nav-item:nth-child(5) {
    border-right: 1px solid #A8A8A8;
}

.nav-item:nth-child(6) {
    border-right: 1px solid #A8A8A8;
}

.navbar {
    padding: 0;
}

.BorderRight {
    border-right: 1px solid #A8A8A8;
}

.carousel-inner .carousel-item img {
    height: 100%;
    background-position: center;
    background-size: cover;
    transform: translate3d(0px, 0px, 0px);
    transition: all;
    width: 100%;
}

.ProductTitle {
    bottom: 0;
    padding: 0.5rem 0.2rem 1rem 0.2rem;
    text-align: center;
    width: 100%;
}

/* .ProductCardOne img {
    width: 150px;
    height: auto;
} */

.ProductCardOne {
    padding: 1.6rem 0 0 0;
    position: relative;
    margin-bottom: 2.6rem;
    height: 300px;
    width: 85%;
    text-align: center;
    background-color: #ECECEC;
    border-radius: 1.5rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ProductOffer {
    position: relative;
}

.ProductOffer span {
    color: #fff;
    font-size: 0.7rem;
    font-weight: 600;
    padding: 0 0.4rem 0 0.4rem;
    background-color: #07B1E7;
    border-radius: 0.6rem;
    position: absolute;
    right: 8px;
    bottom: 8px;
}

.ProductBtn {
    position: absolute;
    bottom: 0.2rem;
    left: 0%;
    right:50%;
    width: 100%;
}

.ProductContent {
    position: absolute;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    bottom: 0;
    text-align: center;
    /*height: 125px;*/
    height: 135px;
    width: 100%;
    font-family: "Inter", sans-serif;
    background-color: #fff;
}

.ProductContent p {
    padding-top: 1rem;
    font-size: 0.8rem;
}

.ProductContent button {
    background-color: #3D97EA;
    color: #fff;
    border-radius: 0.3rem;
    font-size: 0.7rem;
    margin-bottom: 0.5rem;
    padding: 0.3rem 1rem 0.3rem 1rem;
    border: none;
    outline: none;
}

.ProductContent .ProductTitle>div:nth-child(1) {
    font-size: 0.8rem;
    font-weight: 500;
}

.ProductContent .ProductTitle>div:nth-child(2) {
    font-size: 0.7rem;
    font-weight: 300;
}

.ProductContent .ProductTitle>div:nth-child(3) {
    font-size: 0.7rem;
    font-weight: 500;
}

.BannerImg {
    width: 100%;
    /*height: 440px;*/
    height: 379px;
}