h1,
h5,
h6,
h2,
h4,
h3 {
  font-weight: 500 !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  cursor: pointer;
}

.RuppeFont {
  font-family: "Mukta", sans-serif !important;
  font-size: 0.9rem;
}

.RuppeFont2 {
  font-family: "Mukta", sans-serif !important;
  font-size: 1.5rem;
}