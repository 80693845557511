.ProDetPageImg{
    display: flex;
    justify-content: flex-start;
    align-items: start;
    margin-bottom: 1rem;
}
.ProDetPageImg .ProdImgSpec{
    margin-top: 1rem;
}
.itemOffText{
    width: fit-content;
    border:1px solid #3D97EA;
    font-size: 0.6rem;
    font-weight: 600;
    padding: 0.1rem 0.5rem 0rem 0.5rem;
    color: #3D97EA;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.ProDetPageImg .ProdImgSpec div{
    height: 80px;
    width: 100px;
    border: 1px solid #A8A8A8;
    border-radius: 0.7rem;
    text-align: center;
    cursor: pointer;
}
.ProDetPageImg .ProdImgSpec img{
    padding-top: 0.5rem;
    height: 65px;
    width: 60px;
    object-fit: contain;
    margin: 0rem 0 0.3rem 0;
}
.ProCheckBox{
    display: flex;
    justify-content: flex-start;
    margin-left: -0.5rem;
}
.ProDetPageImg .ProdImgSpec div:nth-child(2){
    margin-top: 0.8rem;
}
.ProDetPageImg .ProdImgSpec .ProImgSdw{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.ProDetPageImg .ProdImgSpec div:nth-child(3){
    margin-top: 0.8rem;
}
.ProDetPageImg .ProdImgSpec div:nth-child(4){
    margin-top: 0.8rem;
}
.ProDetPageImg .ProdImgSpec2 {
    margin-left: auto;
    margin-right: 1.5rem;
    border: 1px solid #A8A8A8;
    height: 359px;
    width: 73%;
    text-align: center;
    border-radius: 0.7rem;
    overflow: hidden; /* Prevents the image from overflowing */
    display: flex;
    align-items: center;
    justify-content: center;
}

    .ProDetPageImg .ProdImgSpec2 img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain; /*Ensures the image scales proportionally */
    }

    .ProDetPageImg .ProdImgSpec2 img:hover{
        cursor:zoom-in;
        transform: scale3d(1.3,1.3,10);
        z-index: 0;
        transition: 1s ease-in-out;
    }

.ProdDetUnderLine{
    border-bottom: 1px solid #A8A8A8;
}
#MoreKnow{
    display: none;
}
.ProdDescText .prodTextInfo{
    cursor: pointer;
}
.ProdDescText p,span{
    font-size: 0.8rem;
}
.ProdRemark{
    border: 1px solid #222222;
    background-color: #fff;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    font-size: 0.7rem;
    border-radius: 0.4rem;
}
.ProdBorder{
    border-right: 1px solid #A8A8A8;
}
.ProdBordeSec div{
    text-align: center;
}
/*.ProdDetInput {
    border: none;
    background: transparent;
    appearance: none;
    width: 70px;
    outline: none;
    text-align: center;
}*/
.ProdDetInput {
    border: none;
    background: transparent;
    appearance: none;
    width: 50%; /* Dynamic width to adapt to container size */
    max-width: 60px; /* Restrict max width */
    outline: none;
    text-align: center;
}
.ProdBorWeight{
    font-weight: 600;
}