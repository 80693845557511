.CategoriesHead h5.active{
    color: #3D97EA;
}
.pagination-container .pagination-item {
    background-color: grey !important;
    color: white !important;
    border: none !important;
}

    .pagination-container .pagination-item.active {
        font-weight: bold !important;
        background-color: grey !important;
        color: white !important;
    }

    .pagination-container .pagination-item:hover {
        background-color: darkgrey !important;
        color: white !important;
    }

