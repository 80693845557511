input::placeholder {
    opacity: 0.5;
    font-size: 0.8rem;
    color: #f5f5f5;
}
textarea::placeholder{
    opacity: 0.5;
    font-size: 0.8rem;
    color: #f5f5f5;
}
.kycShadowWrap{
    max-height: 150px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 1rem;
}