.LandHead{
    margin: 0 4.5rem 0 4.5rem;
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.LandButton{
    background-color: #0E45D4;
    height: 40px;
    border: none;
    color: #fff;
    padding: 0.4rem 1rem 0.5rem 1rem;
    border-radius:0.9rem;
}
.LandContact{
    cursor: pointer;
}